import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WebModal from "./webModal";
import { Box, Modal, useMediaQuery, CircularProgress } from "@mui/material";

import { useNavigate } from "react-router";
import { useUser } from "../../../context/UserContext";

import "./index.css";
import styled from "@emotion/styled";
import MobModal from "./mobModal";
import Sidebar from "../sidebar/Sidebar";

const Inbox = () => {
  const is2400 = useMediaQuery("(min-width:2400px)");
  const isMobile = useMediaQuery("(max-width:899.99px)");
  const is400 = useMediaQuery("(max-width:399.99px)");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const loggedIn = localStorage.getItem("userCredential");

  const navigate = useNavigate();
  const { setAuthenticated } = useUser();

  useEffect(() => {
    // Fetch data from firebase
    const dbRef = ref(getDatabase(), "userDataRecords/");
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setData(snapshot.val());
        setLoading(false);
      } else {
        console.log("No data available");
      }
    });

    if (!loggedIn && loading) {
      localStorage.setItem("attemptedPath", "/inbox");
      navigate("/login");
    }

    const timer = setTimeout(() => {
      if (loggedIn) {
        localStorage.removeItem("userCredential");
      }
    }, 1800000);

    return () => {
      unsubscribe();
      clearTimeout(timer);
    };
  }, [loggedIn, loading, navigate]);

  const StyledModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  });

  const openModal = (userData) => {
    setUserData(userData);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const limitData = (data, maxLength) => {
    if (data.length > maxLength) {
      return data.substring(0, maxLength) + "...";
    }
    return data;
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress size={60} />
        <p className="pt-4">Fetching messages...</p>
      </div>
    );
  }

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <Box width={is2400 ? "2060px" : "100%"} margin="0 auto">
      <Sidebar />
      <h1 className="pr-4 pl-4 pt-8 pb-8 text-2xl sm:text-4xl text-center font-bold">
        IrenicTech Inbox
      </h1>

      {isMobile && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 280 }} aria-label="simple table">
            <TableHead>
              <TableRow className="tableHeading">
                <TableCell align="center" className="tableHeadingCell">
                  Full Name
                </TableCell>
                {!is400 && (
                  <TableCell align="center" className="tableHeadingCell">
                    Email
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data).map((key, i) => (
                <>
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="tableRow"
                    onClick={() => {
                      openModal(data[key]);
                    }}
                  >
                    <TableCell scope="row" align="center">
                      {data[key].full_name}
                    </TableCell>
                    {!is400 && (
                      <TableCell align="center"> {data[key].email}</TableCell>
                    )}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isMobile && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="tableHeading">
                <TableCell align="center" className="tableHeadingCell">
                  Full Name
                </TableCell>
                <TableCell align="center" className="tableHeadingCell">
                  Email
                </TableCell>
                <TableCell align="center" className="tableHeadingCell">
                  Phone No
                </TableCell>
                <TableCell align="center" className="tableHeadingCell">
                  Platform
                </TableCell>
                <TableCell align="center" className="tableHeadingCell">
                  Project Brief
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data).map((key) => (
                <>
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="tableRow"
                    onClick={() => {
                      openModal(data[key]);
                    }}
                  >
                    <TableCell scope="row" align="center">
                      {data[key].full_name}
                    </TableCell>
                    <TableCell align="center"> {data[key].email}</TableCell>
                    <TableCell align="center"> {data[key].phone_no}</TableCell>
                    <TableCell align="center"> {data[key].platform}</TableCell>
                    <TableCell align="center">
                      {limitData(data[key].project_brief, 20)}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledModal open={modal} onClose={closeModal}>
        {isMobile ? (
          <MobModal close={closeModal} userData={userData} />
        ) : (
          <WebModal close={closeModal} userData={userData} />
        )}
        {/* <WebModal  /> */}
      </StyledModal>
    </Box>
  );
};

export default Inbox;
