import * as Yup from "yup";

export const contactFormSchema = Yup.object({
  full_name: Yup.string().required("Field cannot be empty"),
  email: Yup.string().email().required("Field cannot be empty"),
  phone_no: Yup.number()
    .positive("Phone No cannot be a negative value")
    .integer()
    .required("Field cannot be empty"),
  platform: Yup.string().required("Field cannot be empty"),
  project_brief: Yup.string(),
});

export const loginFormSchema = Yup.object({
  email: Yup.string().email().required("Field cannot be empty"),
  password: Yup.string().min(6).required("Field cannot be empty"),
});
