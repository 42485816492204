import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Pie } from "react-chartjs-2";
import "./pie.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ usersDeviceCategory }) => {
  let mobileUsers = 0;
  let desktopUsers = 0;
  let tabletUsers = 0;

  usersDeviceCategory.forEach((row) => {
    if (row.deviceCategory == "desktop") {
      desktopUsers += row.activeUsers;
    } else if (row.deviceCategory == "mobile") {
      mobileUsers += row.activeUsers;
    } else if (row.deviceCategory == "tablet") {
      tabletUsers += row.activeUsers;
    }
  });

  const data = {
    labels: ["Mobile", "Desktop", "Tablet"],
    datasets: [
      {
        data: [mobileUsers, desktopUsers, tabletUsers],
        backgroundColor: ["#4762A6", "#E9A34D", "#8ED2E6"],
      },
    ],
  };

  return (
    <div className="pie-outer-wrapper">
      <div>
        <Pie data={data} />
      </div>
    </div>
  );
};

export default PieChart;
