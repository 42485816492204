import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import { useMediaQuery } from "@mui/material";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const LineChart = ({ dateReportData }) => {
  const is600 = useMediaQuery("(max-width:600px)");

  if (!Array.isArray(dateReportData)) {
    return null;
  }

  const dateArray = [];
  const userArray = [];

  dateReportData.forEach((row) => {
    dateArray.push(row.date);
    userArray.push(row.activeUsers);
  });

  const data = {
    labels: dateArray,
    datasets: [
      {
        label: "Users Per Day",
        data: userArray,
        backgroundColor: "aqua",
        borderColor: "black",
        pointBorderColor: "aqua",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  return (
    <div
      style={{
        width: is600 ? "100%" : "600px",
        height: is600 ? "100%" : "300px",
        backgroundColor: "whitesmoke",
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
