import { Box, Grid, useMediaQuery } from "@mui/material";
import "./index.css";
import { useEffect, useState } from "react";
import MUIDrawer from "./Drawer";

const Sidebar = () => {
  const isMobile = useMediaQuery("(max-width:899.95px)");
  const is1100 = useMediaQuery("(min-width:900px) and (max-width:1100px)");
  const is1536 = useMediaQuery("(min-width:1536px) and (max-width:1659px)");
  const is2400 = useMediaQuery("(min-width:2400px)");
  const [scroll, setScroll] = useState(false);

  let navLinks = document.getElementsByClassName("navLink");

  let menu = document.getElementsByClassName("dropBox");
  let navbar = document.querySelector(".navbar");

  return (
    <>
      <Box
        width={is2400 ? "2060px" : "100%"}
        margin="0 auto"
        id="nav-bg"
        className="sticky top-0 z-10 admin-nav-bgMob"
      >
        <Grid
          container
          className="text-white flex box-border items-center cursor-pointer pt-[10px]"
        >
          <Grid item sm={1.5} className="dropBox">
            <MUIDrawer />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Sidebar;
