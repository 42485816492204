import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";

import { UserProvider } from "../src/context/UserContext";
import { BrowserRouter as Router } from "react-router-dom";

// ReactGA.initialize("G-WMKXWWNW11");
// ReactGA.initialize("G-GQCEFD8996");
ReactGA.initialize("G-QEZ6BDYV8Q");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <UserProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </UserProvider>
  </Router>
);
