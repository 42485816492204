import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./components/admin/login";
import GoogleAnalyticsReport from "./components/admin/googleAnalytics";
import Inbox from "./components/admin/inbox";
import { useUser } from "./context/UserContext";

const Home = lazy(() => import("./views/home"));

function PrivateRoute({ element }) {
  const { userCredential } = useUser();

  if (userCredential) {
    return element;
  } else {
    return <Login />;
  }
}

function App() {
  return (
    <div>
      <section>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/inbox" element={<PrivateRoute element={<Inbox />} />} />
          <Route
            path="/reports"
            element={<PrivateRoute element={<GoogleAnalyticsReport />} />}
          />
        </Routes>
      </section>
    </div>
  );
}

export default App;
