import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// MUI Icons

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import CodeIcon from "@mui/icons-material/Code";

import "./modal.css";

const MobModal = ({ close, userData }) => {
  return (
    <>
      <Box className="admin-mob-modal-container relative">
        <div className="close-btn-wrapper" onClick={close}>
          <button>
            <CloseIcon className="close-btn" />
          </button>
        </div>
        <div className="admin-mob-modal-wrapper pl-2 pr-2 pb-8">
          <div className="mob-email-wrapper">
            <h2 className="mob-heading">
              New message from{" "}
              <span className="userName">{userData.full_name}</span>
            </h2>

            {/* User Info Section */}

            <div className="user-info-div">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cells" align="left">
                        <div className="header-text">User Information</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <PersonIcon className="icon" />
                          Full Name
                        </div>
                        <div className="text">
                          <span className="user-data-response">
                            {userData.full_name}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <EmailIcon className="icon" />
                          Email
                        </div>
                        <div className="text">
                          <span className="user-data-response email">
                            <a href={`mailto:${userData.email}`}>
                              {userData.email}
                            </a>
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <CallIcon className="icon" /> Phone No
                        </div>
                        <div className="text">
                          <span className="user-data-response">
                            {userData.phone_no}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <CodeIcon className="icon" />
                          Platform
                        </div>
                        <div className="text">
                          <span className="user-data-response">
                            {userData.platform}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {/* User Message Section */}

            <div className="user-msg-div">
              <TableContainer component={Paper} className="pt-8">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cells" align="left">
                        <div className="header-text">User Message</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        {userData.project_brief}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default MobModal;
