import React from "react";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./modal.css";

// MUI Icons

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import CodeIcon from "@mui/icons-material/Code";

const WebModal = ({ close, userData }) => {
  return (
    <>
      <Box className="admin-modal-container relative">
        <div
          className="absolute z-10 top-0 right-6 pt-1 cursor-pointer"
          onClick={close}
        >
          <button>
            <CloseIcon className="hover:text-orange-300" />
          </button>
        </div>
        <div className="admin-modal-wrapper pl-8 pr-8">
          <div className="email-heading">
            <h2 className="text-3xl font-bold pb-8 pt-2 text-center">
              New message from{" "}
              <span className="userName">{userData.full_name}</span>
            </h2>
          </div>

          <div className="email-body">
            <div className="left">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cells" align="left">
                        <div className="header-text">User Information</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <PersonIcon className="icon" />
                          Full Name
                        </div>
                        <div className="text">
                          <span className="user-data-response">
                            {userData.full_name}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <EmailIcon className="icon" />
                          Email
                        </div>
                        <div className="text">
                          <span className="user-data-response email">
                            <a href={`mailto:${userData.email}`}>
                              {userData.email}
                            </a>
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <CallIcon className="icon" /> Phone No
                        </div>
                        <div className="text">
                          <span className="user-data-response">
                            {userData.phone_no}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className="table-cells" align="left">
                        <div className="icon-wrapper">
                          <CodeIcon className="icon" />
                          Platform
                        </div>
                        <div className="text">
                          <span className="user-data-response">
                            {userData.platform}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="right">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 335 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cells" align="left">
                        <div className="header-text">User Message</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells" align="left">
                        {userData.project_brief}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default WebModal;
