import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { useFormik } from "formik";

import { loginFormSchema } from "../../../schemas";
import { useUser } from "../../../context/UserContext";

import { Grid, Stack, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Login = () => {
  const { setAuthenticated } = useUser();
  const navigate = useNavigate();
  const [userCredential, setCredential] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [status, setStatus] = useState(null);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (userCredential) {
      showSuccessMsg();
    }
  }, [status]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginFormSchema,
      onSubmit: (values, action) => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {})
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setStatus("error");
            console.log(errorCode, errorMessage);
          });
      },
    });

  const showSuccessMsg = () => {
    setStatus("success");

    if (setAuthenticated && status === "success") {
      setTimeout(() => {
        const attemptedPath = localStorage.getItem("attemptedPath");
        console.log(attemptedPath);
        // navigate(attemptedPath || "/inbox");
        navigate("/inbox");
        // Clear the stored attempted path
        localStorage.removeItem("attemptedPath");
      }, 1000);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    setStatus("sending");
    setOpenSnackbar(true);
    const { email, password } = values;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setAuthenticated(userCredential);
        setCredential(userCredential);
        showSuccessMsg();
        navigate("/inbox");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setStatus("error");
        console.log(errorCode, errorMessage);
      });
  };

  const inputStyle = "bg-transparent border-2 white rounded-lg w-full h-12 p-2";
  const msgStyle = "bg-transparent border-2 white rounded-lg w-full h-24 p-2";
  const btnStyle =
    "text-lg bg-transparent border-2 white rounded-lg w-full h-12 hover:bg-orange-400 hover:font-bold hover:scale-110 hover:transform transition duration-300 ease-out";
  const labelStyle = "text-lg";

  return (
    <Grid container className="orange-bg h-[900px] flex items-center">
      <form className="w-full" onSubmit={handleFormSubmit}>
        <Grid container className="pt-16">
          <Grid
            container
            className="flex relative justify-center text-center text-2xl sm:text-4xl pl-10 pr-10 font-bold"
          >
            <Grid item md={6}>
              Log In
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="flex justify-center mt-4">
          <Grid item md={4} sm={10} xs={10}>
            <label htmlFor="email" className={labelStyle}>
              Email*
            </label>{" "}
            <br />{" "}
            <input
              type="email"
              className={inputStyle}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <p className="form-error">{errors.email}</p>
            ) : null}
          </Grid>
        </Grid>

        <Grid container className="flex justify-center mt-4">
          <Grid item md={4} sm={10} xs={10}>
            <label htmlFor="email" className={labelStyle}>
              Password*
            </label>{" "}
            <br />{" "}
            <input
              type="password"
              className={inputStyle}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.password && touched.password ? (
              <p className="form-error">{errors.password}</p>
            ) : null}
          </Grid>
        </Grid>

        <Grid container className="flex justify-center mt-4 pb-[50px]">
          <Grid item md={2} sm={6} xs={8} className="relative">
            <button className={btnStyle} value="Send" type="submit">
              Log In
            </button>
          </Grid>
        </Grid>
      </form>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={
              status === "success"
                ? "success"
                : status == "sending"
                ? "info"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {status === "success" ? "Login successfull!" : undefined}

            {status === "sending" ? "Logging in..." : undefined}

            {status === "error" ? "Login unsuccessful! Try again." : undefined}
          </Alert>
        </Snackbar>
      </Stack>
    </Grid>
  );
};

export default Login;
