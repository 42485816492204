import React, { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userCredential, setUserCredential] = useState(null);

  // Function to set the user's authentication status in browser storage
  const setAuthenticated = (user) => {
    setUserCredential(user);
    localStorage.setItem("userCredential", JSON.stringify(user));
  };

  // Function to clear the user's authentication status from browser storage
  const clearAuthenticated = () => {
    setUserCredential(null);
    localStorage.removeItem("userCredential");
  };

  // Check for authentication status from browser storage when the app starts
  useEffect(() => {
    const storedUserCredential = localStorage.getItem("userCredential");
    if (storedUserCredential) {
      setUserCredential(JSON.parse(storedUserCredential));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userCredential,
        setAuthenticated,
        clearAuthenticated,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
