import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBCsM9icHg6MdhKingwnsDlhdF7cd9TBiY",
  authDomain: "irenictech-contact-b80f4.firebaseapp.com",
  databaseURL: "https://irenictech-contact-b80f4-default-rtdb.firebaseio.com",
  projectId: "irenictech-contact-b80f4",
  storageBucket: "irenictech-contact-b80f4.appspot.com",
  messagingSenderId: "714507710314",
  appId: "1:714507710314:web:1c576bda99246cc7533f13",
};

// // kashir.irenictech
// const firebaseConfig = {
//   apiKey: "AIzaSyDcZclyvR1p9XAQwNHpAp8KbbDvj2g9E-U",
//   authDomain: "irenictech-contact.firebaseapp.com",
//   databaseURL: "https://irenictech-contact-default-rtdb.firebaseio.com",
//   projectId: "irenictech-contact",
//   storageBucket: "irenictech-contact.appspot.com",
//   messagingSenderId: "80693796526",
//   appId: "1:80693796526:web:a5879f6d30ebef25c2973f",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const database = getDatabase(app);
export default app;
