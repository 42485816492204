import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, useMediaQuery, CircularProgress } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PieChart from "../charts/pie";

import "./index.css";
import LineChart from "../charts/line";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";

const GoogleAnalyticsReport = () => {
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("userCredential");
  const is1100 = useMediaQuery("(max-width:1100px)");
  const [isLoading, setIsLoading] = useState(true);

  // Report states

  const [cityReportData, setCityReportData] = useState([]);
  const [dateReportData, setDateReportData] = useState([]);
  const [pageViewsReportData, setPageViewsReportData] = useState([]);
  const [usersDeviceReportData, setUsersDeviceReportData] = useState([]);

  useEffect(() => {
    if (!loggedIn) {
      localStorage.setItem("attemptedPath", "/reports");
      navigate("/login"); // Redirect to the login page if not authenticated
    }

    const timer = setTimeout(() => {
      if (loggedIn) {
        localStorage.removeItem("userCredential");
      }
    }, 1800000);

    const fetchReportData = async () => {
      try {
        const response = await axios.get(
          "https://irenictech-reports.cyclic.cloud/api/google-analytics-report"
        );
        setCityReportData(response.data.cityReportData);
        setDateReportData(response.data.dateReportData);
        setPageViewsReportData(response.data.pageViewsReportData);
        setUsersDeviceReportData(response.data.userDeviceReportData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching the report:", error);
      }
    };

    fetchReportData();
  }, []);

  return (
    <Grid container className="flex mb-10">
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress size={60} />
          <p className="pt-4">Fetching reports...</p>
        </div>
      ) : (
        <>
          <Sidebar />
          <Grid
            container
            className="flex relative justify-center text-center text-2xl sm:text-4xl pt-10 pb-10 font-bold"
          >
            <Grid item md={12}>
              Google Analytics Reports
            </Grid>
          </Grid>

          <div className="flex relative justify-center text-center text-2xl sm:text-3xl font-bold pb-10 w-full">
            Top 5 Pages By Views
          </div>

          <div className="table-wrapper">
            <div className={is1100 ? "w-full" : "table-inner-wapper"}>
              <TableContainer component={Paper}>
                <Table className="table" aria-label="simple table">
                  <TableHead>
                    <TableRow className="tableHeading">
                      <TableCell align="center" className="tableHeadingCell">
                        Page
                      </TableCell>
                      <TableCell align="center" className="tableHeadingCell">
                        Views
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageViewsReportData != null
                      ? pageViewsReportData.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            className="tableRow"
                          >
                            <TableCell scope="row" align="center">
                              {row.pagePath}
                            </TableCell>
                            <TableCell align="center">
                              {row.screenPageViews}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="flex relative justify-center text-center text-2xl sm:text-3xl font-bold pt-10 pb-10 w-full">
            Users Per Day
          </div>

          <div className="line-wrapper">
            {dateReportData.length > 0 && (
              <LineChart dateReportData={dateReportData} />
            )}
          </div>

          <div className="flex relative justify-center text-center text-2xl sm:text-3xl font-bold pt-10 pb-10 w-full">
            Users by City
          </div>

          <div className="table-wrapper">
            <div className={is1100 ? "w-full" : "table-inner-wapper"}>
              <TableContainer component={Paper}>
                <Table className="table" aria-label="simple table">
                  <TableHead>
                    <TableRow className="tableHeading">
                      <TableCell align="center" className="tableHeadingCell">
                        City
                      </TableCell>
                      <TableCell align="center" className="tableHeadingCell">
                        Users
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cityReportData != null
                      ? cityReportData.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            className="tableRow"
                          >
                            <TableCell scope="row" align="center">
                              {row.city}
                            </TableCell>
                            <TableCell align="center">
                              {row.activeUsers}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="flex relative justify-center text-center text-2xl sm:text-3xl font-bold pt-10 pb-10 w-full">
            Users by Device Category
          </div>

          {usersDeviceReportData.length > 0 && (
            <PieChart usersDeviceCategory={usersDeviceReportData} />
          )}
        </>
      )}
    </Grid>
  );
};

export default GoogleAnalyticsReport;
