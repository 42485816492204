import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Drawer } from "@mui/material";
import { useUser } from "../../../context/UserContext";

// MUI Icons
import MenuIcon from "@mui/icons-material/Menu";
import MessageIcon from "@mui/icons-material/Message";
import InsightsIcon from "@mui/icons-material/Insights";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";

const MUIDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setAuthenticated } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Here, you can add any necessary logout logic, such as clearing local storage, etc.
    // For example, you can clear the user credential from local storage and set the authenticated state to false.
    localStorage.removeItem("userCredential");
    setAuthenticated(false);

    // Redirect the user to the login page after logout.
    // You can use the `useNavigate` hook to achieve this.
    // const navigate = useNavigate();
    navigate("/login");
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="logo"
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      >
        <MenuIcon className="menuIcon" />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        className="relative"
        PaperProps={{
          sx: {
            backgroundColor: "#1D1B1B",
            top: "55px",
            display: "flex",
            textAlign: "center",
          },
        }}
      >
        <Box p={2} width="250px" textAlign="center" role="presentation"></Box>
        <img
          src="assets/icons/decorative/circle.webp"
          alt="a white circle icon"
          className="drawerCircle"
        />
        <ul className="drawerList">
          <li className="p-6 ">
            <NavLink
              to="/inbox"
              className="font-bold text-white drawer-items"
              activeClassName="active"
              onClick={() => setIsDrawerOpen(false)}
            >
              <span>
                <MessageIcon className="drawer-icon" />
              </span>
              <span className="drawer-link-name">Inbox</span>
            </NavLink>
          </li>
          <li className="p-6">
            <NavLink
              to="/reports"
              className="font-bold text-white drawer-items"
              activeClassName="active"
              onClick={() => setIsDrawerOpen(false)}
            >
              <span>
                <InsightsIcon className="drawer-icon" />
              </span>
              <span className="drawer-link-name">Reports</span>
            </NavLink>
          </li>
          <li className="p-6 ">
            <NavLink
              to="/"
              className="font-bold text-white drawer-items"
              activeClassName="active"
              onClick={() => setIsDrawerOpen(false)}
            >
              <span>
                <LanguageIcon className="drawer-icon" />
              </span>
              <span className="drawer-link-name">Website</span>
            </NavLink>
          </li>
          <li className="p-6">
            <NavLink to="/login">
              <a
                className="font-bold text-white drawer-items"
                onClick={handleLogout}
              >
                <span>
                  <LogoutIcon className="drawer-icon" />
                </span>
                <span className="drawer-link-name">Logout</span>
              </a>
            </NavLink>
          </li>
        </ul>
      </Drawer>
    </>
  );
};

export default MUIDrawer;
